import React, { Component } from "react";
import { Button, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import ToggleDisplay from "react-toggle-display";
import kritipurroad from "../../../images/kritipurroad.png";
import pokhararoad from "../../../images/pokhararoad.png";
import { BH, BH2, ContentB } from "../BlogElements";

export default class Background extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { show: false };
  }

  handleClick() {
    this.setState({
      show: !this.state.show,
    });
  }

  render() {
    const { show } = this.state;
    let title = this.state.show ? "Show less" : "Read more";
    return (
      <>
        <BH id="first">INSIDE GREEN ROAD</BH>
        <BH2>A scientific and technical background on Green roads</BH2>
        Waste plastic mixed with bitumen results in construction material with
        enhanced properties that improves the road life and reduce the need for
        maintenance. The main steps that are followed while construction of
        Greener Roads are:
        {/* <button onClick={ () => this.handleClick() }>Toggle things</button> */}
        1. Collection: Collection of plastic wastes from community or industrial
        waste streams.
        <br />
        2. Cleaning: Cleaning of plastics if they were mixed with wastes that
        cannot be processed. (If proper waste segregation and waste auditing is
        done, this labor intensive process is not required.)
        <br />
        3. Shredding: Plastic wastes are shredded to a suitable size needed to
        utilize for road construction.
        <br />
        4. Processing: The shredded plastics are heated up and mixed with
        aggregates before adding bitumen so as to increase surface properties
        like binding and water resistance.
        <br />
        5.Paving: The roads are now ready to pave! Without the use of any extra
        machinery and in a foolproof and greener way.
        <br />
        <br />
        <ToggleDisplay if={this.state.show} tag="section">
          These roads withstand loads due to heavy traffic, rain and temperature
          variation. But, the employment of plastic waste as modifier is
          successful only if it coats the aggregate well and the aggregate
          becomes non‐wetting with enhanced mechanical properties like
          enhancement of useful properties like strength, stability, longevity
          and durability of roads.
          <br />
          <br /> The Green Roads are designed particularly for benefits like
          protection from weather erosion, considerably reduction in the cost of
          the constructed road component and reduction in initial cost and
          maintenance requirements of roads. In order to check for the inclusion
          of plastic in the aggregate bitumen mix, various characterization
          techniques have been used and tested in labs and are listed here:
          <br />
          <br />
          Stripping test (IS: 6241‐1971)
          <br />
          Los Angeles Abrasion Test
          <br />
          Aggregate Crushing Test
          <br />
          Aggregate Impact Test
          <br />
          Marshall Stability Test
          <br />
          Water absorption test
          <br />
          Extraction of bitumen Test
          <br />
          The result obtained for plastic coated aggregate turned out to be the
          best in all of the above tests conducted in the lab. The practical
          experience from the plastic roads built in foreign countries, we
          learnt that the durability of the roads are higher than ordinary
          roads.
          <br />
          Where have we successfully constructed roads:
          <Col xs={1} md={8} className="m-auto">
            <Image src={pokhararoad} fluid />
          </Col>
          <ContentB>
            Pokhara Municipality
            <br />
            Length: 100 meters
            <br />
            Width : 6 meters
            <br />
            Thickness : 20 mm
            <br />
            Date : June 2018
            <br />
            Amount of plastic used : 300 kgs
            <br />
            Amount of Bitumen saved : 250 kgs
            <br />
            Reduced carbon emission by : 900 kgs
          </ContentB>
          <br />
          <br />
          <Col xs={18} md={8} className="m-auto">
            <Image src={kritipurroad} fluid />
          </Col>
          <ContentB>
            Kirtipur Municipality
            <br />
            Length of the road: 200 meters
            <br />
            Width of the road : 6 meters
            <br />
            Thickness of the road : 40 mm
            <br />
            Date : April 2019
            <br />
            Amount of plastics used : 750 kgs
            <br />
            Amount of Bitumen saved : 600 kgs
            <br />
            Reduced carbon emission by : 2000 kgs
            <br />
          </ContentB>
        </ToggleDisplay>
        <Button onClick={() => this.handleClick()} variant="success" size="sm">
          {title}
        </Button>
      </>
    );
  }
}
