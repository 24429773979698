import React from 'react';
import { Button } from 'react-bootstrap';
import { ExternalLink } from 'react-external-link';
import annapurnapost from '../../images/annapurnapost.jpg';
import himalyantimes from '../../images/himalyantimes.jpg';
import kantipurlogo from '../../images/kantipurlogo.png';
import setopati from '../../images/setopati.jpg';
import { NewsCard, NewsContainer, NewsH1, NewsH2, NewsIcon, NewsP, NewsWrapper } from './NewsElements';

 const styles = {
     button :{
         backgroundColor : 'green',
     }
 }
 const NewsSection = ({buttonlabel, alt, primary, dark, dark2}) => {
     return(
         <NewsContainer id ="news">
             <NewsH1>GREEN ROAD IN MEDIA</NewsH1>
             <NewsWrapper>
                 <NewsCard>
                     <NewsIcon src ={kantipurlogo} />
                     <NewsH2>KANTIPUR DAILY</NewsH2>
                     <NewsP>प्लास्टिकको प्रयोग गरि सडक कालोपत्रे गर्ने प्रविधि नेपाल भित्रिएको छ </NewsP>
                     <ExternalLink href = "https://ekantipur.com/pradesh-4/2018/07/29/15328350201653637.html">
                     <Button variant = "success" size = "sm">Read more</Button>
                    
                  </ExternalLink>
                 </NewsCard>
                 <NewsCard>
                     <NewsIcon src ={annapurnapost} />
                     <NewsH2>ANNAPURNA POST</NewsH2>
                     <NewsP>सडक पिच गर्न प्लास्टिक प्रयोग !</NewsP>
                     <ExternalLink href= "http://annapurnapost.com/news/103185">
                     <Button variant="success" size = "sm" color='#5cb85c'>Read more</Button>
                     </ExternalLink>
                 </NewsCard>
                 <NewsCard>
                     <NewsIcon src ={setopati} />
                     <NewsH2>SETOPATI</NewsH2>
                     <NewsP>आमिर खानले चार वर्षअघि देखाएको 'प्लास्टिक सडक' अब पोखरामै</NewsP>
                     <ExternalLink href = "https://www.setopati.com/social/166805">
                     <Button variant="success" size = "sm" >Read more</Button>
                      </ExternalLink>
                     
                 </NewsCard>
                 <NewsCard>
                     <NewsIcon src ={himalyantimes} />
                     <NewsH2>THE HIMALYAN TIMES</NewsH2>
                     <NewsP>Plastic roads: The way forward?</NewsP>
                    
                     <ExternalLink href ="https://thehimalayantimes.com/opinion/plastic-roads-the-way-forward/">
                     <Button variant="success" size = "sm">Read more</Button>  
                            </ExternalLink>
                        
                      
                 </NewsCard>
             </NewsWrapper>
             <br />
             <br />
         </NewsContainer>
     )
 }
 export default NewsSection