import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";



import {
  Background,
  CloseModalButton,
  Heading,
  ModalContent,
  ModalWrapper,
} from "./ModalElements";


toast.configure()


export const ModalInner = ({ showModal, setShowModal }) => {
  const [data, setData] = useState({
    name: "",
    mobile: "",
    address: "",
    date: "",
    time: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  function handleSubmit(e) {
    // TODO: add spinner 
    e.preventDefault();
   
    
  
    
    setIsLoading(true);
    var config = {
      method: "post",
      url: "https://greenroad.onrender.com/webentry",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    try{
      axios(config)
        .then(function ({ data }) {
          // TODO remove spinner
          setIsLoading(false);
          console.log("result", data);
           toast("Thank you! Your request has been saved.");
        })
        .catch(function (error) {
          // TODO remove spinner and show appro. msg.
          setIsLoading(false);
          console.log(error);
          toast("Sorry! We could not process your request at the moment.");
        });
    } catch(e){
      setIsLoading(false);
      console.log(e);
    } finally {
      setData({
        name: "",
        mobile: "",
        date: "",
        time: "",
        address: "",
        type: "",
      });
    }
   
  }
  
  function notify(){
    toast('Thank you!')
  }
  function handle(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  return (
    <>
      {showModal ? (
        <Background>
          <ModalWrapper showModal={showModal}>
            <ModalContent>
              <Heading>Sell your Plastics!</Heading>
              <br />
              <Form>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Enter your name </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name "
                      onChange={(e) => handle(e)}
                      id="name"
                      value={data.name}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>Mobile Number </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Mobile no "
                      onChange={(e) => handle(e)}
                      id="mobile"
                      value={data.mobile}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Address"
                      onChange={(e) => handle(e)}
                      id="address"
                      value={data.address}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter Date"
                      onChange={(e) => handle(e)}
                      id="date"
                      value={data.date}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Time</Form.Label>
                    <Form.Control
                      type="time"
                      placeholder="Select time"
                      onChange={(e) => handle(e)}
                      id="time"
                      value={data.time}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3">
                    <Form.Label>Type</Form.Label>

                    <Form.Check
                      type="radio"
                      label="Sell"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios1"
                      onChange={() => setData({ ...data, type: "sell" })}
                    />
                    <Form.Check
                      type="radio"
                      label="Donate"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios2"
                      onChange={() => setData({ ...data, type: "donate" })}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>

              {/* <button
                onClick={() => handleSubmit() }
              >
                Continue
              </button> */}
              <Button
                variant="success"
                disabled={isLoading}
                onClick={(e) =>
                 
                   handleSubmit(e)
                  
                  
                }
                
              >
                {isLoading ? <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> : null}
                
                {''}Continue
              </Button>
              {/* <ToastContainer /> */}
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => setShowModal((prev) => !prev)}
              />
            </ModalContent>
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};
