import './App.css';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import MessengerCustomerChat from 'react-messenger-customer-chat';
import 'react-toastify/dist/ReactToastify.css';
import team from '../src/components/about/teamPage';
import blog from '../src/components/blogs/blog';
import Home from './pages';
import index from '../src/components/about/teamPage';

import test from './components/about/teamPage/test';
function App() {
  return (
   <Router> 
        <MessengerCustomerChat
        pageId= "626975134343841"
        appId = "5509620762412358"
        themeColor = "#00B74A"
        />
     <Switch>
         <Route  exact path="/" component={Home} />
        <Route path = "/facts" component = {blog}/>
        <Route path = "/about" component = {team}/>
        <Route path = "/test" component = {test}/>
       
     
     </Switch>
    
    </Router>
    
  );
}

export default App;
