import React, { Component } from 'react';
import { Button, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import ToggleDisplay from 'react-toggle-display';
import wasterecycling from '../../../images/wasterecycling.png';
import { BH2 } from '../BlogElements';


export default class WasteAuditing extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { show: false };
  }
 
  handleClick() {
    this.setState({
      show: !this.state.show
    });
  }

  render(){
    const { show } = this.state;
    let title = this.state.show? "Show less": "Read more";


        return(
        <>

        <BH2>How Waste Audit Can Solve Waste Problem?</BH2>
  Waste is an inevitable by-product of human life and management of that waste is 
  the burning issue of today’s world. Proper management of waste requires proper 
  action and plan from its inception to its final disposal (United Nations Statistics Division, 
  2017). Waste management includes the collection, transport, treatment, and disposal 
 of waste. Each waste has different disposal methods. Proper management of waste 
 is important for sustainable cities and a healthy environment. For proper waste 
 management proper guidance, examination and assistance is important. These 
 guidance, examination and assistance is known as auditing. We all know about the 
 meaning of auditing.
        <ToggleDisplay if={this.state.show} tag="section">
        <Col xs={18} md={14} className="m-auto">
                <Image src= {wasterecycling} fluid />
                </Col>
        Financial Audit is an important term used in accounting for the 
evaluation and examination of a company’s financial statement. These examinations 
which are done in financial transactions can be done in waste for its management 
which is called Waste Audit but the word Waste Audit might not be familiar to 
everyone. For any waste management program to be effective, accurate 
information on the quantity and composition of the waste stream must be known. 
Waste Audit is the first step toward waste management. It is a method for the analysis 
of waste composition that helps to determine the characteristics of the residual waste 
generated by offices, organizations, institutions, and households. Management of 
waste includes all administrative, financial, legal, planning, and engineering functions 
(Ramachandra & Ramachandra and Varghese).
<br />
<br />
Waste Audit should have been the talk of the town in today’s situation as they actually 
help in waste reduction and waste utilization but sadly it’s not the case. As the 
population increases the amount of waste generated is increasing across the world. 
According to the EPA (Environmental Protection Agency), the average American 
person will produce about 5.91 pounds of trash, with about 1.51 pounds being 
recycled; 4.40 pounds is the rough average daily waste per person. So, in that case, 
the waste audit will diagnose the waste we generate, help us to characterize and 
quantify waste streams, verify waste pathways, divide waste into categories & suggest 
recyclability prospects, and identify potential environmental impacts from the 
generation of waste. As all the waste we generate may not be wasted and ends up 
in landfill sites; some waste can be a source of income whereas some can be the 
nutrition for plants and some can be converted into energy. Waste auditing tools 
make sure that the most effective way to minimize the waste & its values & 
recyclability prospects. We all know waste generation has two sides’ production side 
and the consumption side. On the production side actions must be taken at the 
industrial level. If the industries or companies have a waste audit then they will have 
an idea for effective processes for manufacturing products with enhanced materials, 
cleaner and safer production, improved design for reuse, recycle and durability, 
reduction of product waste, facilitate recycling. At the consumption level, the first step 
is awareness of the impacts of waste and the role of individuals. A waste audit inspires 
in changing consumer behavior and lifestyles of people. For example, home 
composting, buying durable things, avoiding the use of disposable cutleries, sorting 
the waste at home can be done with realization & motivation after awareness & 
waste audit. Waste Audit will help in waste minimization measures that can be taken at the institutional level such as thinking green while procuring products for institutions 
like offices, businesses, and schools, practicing waste segregation and adopting 
recycling techniques, and managing cafeteria waste by composting thereby 
reducing the quantity of waste going to landfill. 
<br />
Waste Auditing has been actively practiced in some parts of the world. In Ontario, it 
is compulsory to perform a waste audit within 6 months of the company’s 
establishment. As per Great Forest Sustainability Solutions waste audits reports of over 
100 offices, businesses, industries e.t.c revealed that 77 percent of waste thrown out is 
actually recyclable. Waste audit data & analysis of a company determine how a 
company can reduce waste, determine the effectiveness of the waste management 
strategies currently in their place, and identify any need for implementing new 
strategies. It also shows that the company is taking responsibility for the waste they 
generate. This also allows their customers to be aware of the initiatives they are taking 
to improve their waste programs. Waste auditing is one of the first steps for a company 
to become a sustainable business. The concept of sustainable business means 
reducing the negative impacts on the environment. Waste auditing focuses on waste 
minimization and also analyzes the lifecycle of materials used in business. They prefer 
durable and reusable materials which will ultimately help in environmental benefits. 
<br />
<br/>
In Nepal as per the study conducted in 2011, around 497 Kg per person per day of 
solid waste is generated from households in Kathmandu Metropolitan city only. As per 
the recent “Assessment of Solid Waste Management Services and Systems” published 
by World Bank Group (2020), an estimated 4,900 tons of solid waste is generated by 
urban areas per day, among which 600 tons of plastic waste gets dumped in landfills 
every day in Nepal. The proper management of solid waste is one of the greatest 
challenges faced by the Nepal Government. The government and the local 
municipal authorities have taken many initiatives towards the improvement of this 
situation. Many private sectors including Green Road Waste Management Private 
Limited have been working in the management of waste. To understand the level of 
success in the initiatives it is very necessary to carry out an audit. They will identify and 
bring out problems and loopholes in the current system. They can reveal wasteful 
problems and provide opportunities. There are many large and small-scale industries 
in Nepal and the waste management by those industries is very pitiful. Most of the 
wastes are either mixed in rivers, thrown on landfills, or are openly burned. According 
to Nepal's Ministry of Health and Population (2012), only 38.7% of hospitals have 
implemented the proper procedure for segregating healthcare wastes. As Nepal is a 
developing country, neither it has any developed technology for waste management 
nor people are educated. It is commonly believed that incineration and landfill 
disposal represent preferred options in dealing with waste products; however, many 
wastes have the potential to be recycled or reused. Some waste materials may be 
reclaimed or re-generated and used again for their original or similar purpose, or they 
may be physically or chemically processed for alternative uses. Waste Audit helps us 
to identify these aspects of waste. They calculate the percentage of recyclable and 
non-recyclable waste, organic waste, waste that can be reused, and also the waste 
that can be refused. There is no proper study or survey conducted on how much waste is generated from every organization or institution. A waste audit is a process 
that can be used to determine the amount and types of waste that are generated. 
In doing this, we identify what is being thro\n away, what is being recycled, and the 
amounts of each type by weight or volume. A waste audit not only verifies what we 
are throwing away but the value we are losing as well. Waste auditing can help us to 
save our money. Conducting waste audits can help to minimize the negative impact 
on the environment and make use of better alternatives to waste disposal. There are 
some major components to the waste audit i.e. preparation, sorting, recording and 
cleanup, and analysis and reporting. We can conduct Waste Auditing with the 
selection of an audit team who will sort the trash by wearing protective clothing. After 
identifying the types and percentages of waste generated on inspection, the team 
has to quantify the amounts(weight) of each of the wastes. They will record waste 
data from the kitchen, library and recommend better waste management 
techniques.
<br />
<br />
Though SWM Act 2011 and Rules 2013, Nepal comprehensively covers all aspects of 
the waste stream (from prevention, collection, and final disposal) but the government 
should conduct educating programs for encouraging waste generators to segregate 
waste at source. Thus, conducting a waste audit is important to set refusing, reducing 
& recycling goals to go green.
</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>
</>
        )}

  }

