import React from "react";
import { Nav, Navbar as Navvbar } from "react-bootstrap";
import greenlogo from "../../images/logo.png";
import { NavLinks } from "./NavbarElements";

const Navbar = () => {
  return (
    <Navvbar bg="white" expand="lg" sticky="top">
      <Navvbar.Brand href="home">
        <img
          src={greenlogo}
          alt=" "
          width="100"
          height="150"
          className="container-fluid"
        />
      </Navvbar.Brand>
      <Navvbar.Toggle aria-controls="navbarScroll" />
      <Navvbar.Collapse id="navbarScroll">
        <Nav
          className="ml-auto"
          //  className="mr-auto my-2 my-lg-0"

          style={{ maxHeight: "350px" }}
          navbarScroll
        >
          <Nav.Link className="linkText">
            <NavLinks to="calculation">CALCULATION</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText">
            <NavLinks to="how">HOW IT WORKS?</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText">
            <NavLinks to="why">WHY GREEN ROAD?</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText">
            <NavLinks to="products">PRODUCTS</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText" href="/about">
            ABOUT
          </Nav.Link>

          <Nav.Link className="linkText">
            <NavLinks to="news">MEDIA</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText" href="/facts">
            BLOG
          </Nav.Link>
          <NavLinks to="blog"></NavLinks>
        </Nav>
      </Navvbar.Collapse>
    </Navvbar>
  );
};
export default Navbar;
