import React, { Component } from 'react';
import { Button, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import ToggleDisplay from 'react-toggle-display';
import greenbrick from '../../../images/greenbrick.jpg';
import { BH2 } from '../BlogElements';


  export default class GreenBricks extends Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.state = { show: false };
    }
   
    handleClick() {
      this.setState({
        show: !this.state.show
      });
    }

    render(){
      const { show } = this.state;
      let title = this.state.show? "Show less": "Read more";
        return(
        <>
        <BH2>Why use Green Bricks?</BH2>
       
        

Green Interlocking Bricks, also called Earth Bricks, is a strong, durable, and 
earthquake-resistant building material suitable for the construction of houses, 
buildings, schools, hospitals, and eventually cities in Nepal. It is a proven earthquakeresistant technology that has been used for decades in earthquake-affected areas 
across India, Pakistan, Iran, Europe, and many other countries. It enables to build high 
quality and earthquake-resistant structures at a low cost. Environmentally friendly, 
strong, reliable & considerably cost-efficient, there is no reason you would not want 
to use green bricks for construction purposes.
<br />
<br />
<ToggleDisplay if={this.state.show} tag="section">
<Col xs={18} md={14} className="m-auto">
                <Image src= {greenbrick} fluid />
                </Col>
We should use Green Interlock Bricks because:
1. Earthquake resistant: Are reinforced by iron rebar inside the wall, both horizontally and 
vertically, making it highly earthquake resistant.
<br />
<br />
2. Durable: It has high crushing strength (7-10Mpa) (stronger than AAC and red bricks)
<br />
<br />
3. Cheaper: Cheaper than red burnt bricks and AAC blocks.
<br />
<br />
4. No plaster: These bricks look aesthetically beautiful and the structure shall look like it 
has a stone cut piece from the outside.
<br />
<br />
5. Eco-friendly: 50% less CO2 emissions compared to fire burnt bricks! Leaving less carbon 
footprint is the greatest way to give back to the environment.
<br />
<br />
6. Government-approved: Department of Urban development and physical infrastructure has approved these bricks by claiming that they are fit for being used 
for construction purposes.
<br />

</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>
</>

        )}

  }
