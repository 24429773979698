import React, { useState } from 'react';
import Video from '../../videos/video.mp4';
import { HeroBg, HeroBtnWrapper, HeroContainer, HeroContent, HeroH1, HeroP, VideoBg } from './HeroElements';
// import { Button } from '../ButtonElement';
import ModalC from '../ModalComp';


const HeroSection = () => {
    const[hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
    return (
        <HeroContainer id ="home">
            
            <HeroBg>
            
                <VideoBg autoPlay loop muted src ={Video} type ='video/mp4' />
              
            </HeroBg>
            <HeroContent>
                <HeroH1> </HeroH1>
                <HeroP>
               
                </HeroP>
                <HeroBtnWrapper>
                {/* <ModalComp className="float-right" /> */}
                <ModalC />
                </HeroBtnWrapper>


            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection;
