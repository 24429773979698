import React, { useState } from 'react';
import Calculation from '../components/Calculation';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjOne } from '../components/InfoSection/Data';
import InfoTwo from '../components/InfoTwo';
import { homeObjTwo } from '../components/InfoTwo/Data';
import Navbar from '../components/Navbar';
import NewsSection from '../components/NewsSection';
import Services from '../components/Services';
import Work from '../components/Work';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
       <>
       {/* <Sidebar isOpen= {isOpen} toggle = {toggle} /> */}
       <Navbar toggle = {toggle} />
        <HeroSection />
        <Calculation />
       <Work />
      <InfoTwo {...homeObjTwo} />
       <Services />
       <InfoSection {...homeObjOne} />
       <NewsSection />
       <Footer />
       </>
    )
}

export default Home
