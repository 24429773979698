import React from "react";
import { MDBCol, MDBRow, MDBCard, MDBMask } from "mdbreact";
import styled from "styled-components";
import Footer from "../../Footer/index";
import Navbar from "../../Navbar/index";
import Mission from "../mission/index";

import ajay from "../../../images/ajay.jpg";
import bimalbasel from "../../../images/bimalbasel.jpg";
import bimalbastola2 from "../../../images/bimalbastola2.jpg";
import nirajan2 from "../../../images/nirajan2.jpg";
import pratima from "../../../images/pratima.jpg";
import rajivsubedi from "../../../images/rajivsubedi.jpg";
import sanjeevbastola2 from "../../../images/sanjeevbastola2.jpg";

const TeamContainer = styled.div`
  text-align: center;
  padding: 60px 20px;
  background: #f9f9f9;
`;

const TeamTitle = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 40px;
`;

const TeamCard = styled(MDBCard)`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: translateY(-5px);
  }
`;

const TeamIcon = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 20px auto;
  display: block;
`;

const TeamName = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  color: #34495e;
`;

const TeamDescription = styled.p`
  font-size: 0.9rem;
  color: #7f8c8d;
  padding: 15px;
`;

const teamMembers = [
  {
    name: "Hom Bahadur Basel, Chairman",
    img: bimalbasel,
    description:
      "                      Hom is a dynamic, experienced and a philanthropic                       businessman of Pokhara. He has been vocal about                       development gaps in Nepal and is working towards providing                       clean drinking water access to local communities in                       Pokhara.",
  },
  {
    name: "Sanjeev Bastola, M. Director",
    img: sanjeevbastola2,
    description:
      "     Sanjeev has an entrepreneurial mindset, a strong work                       ethic, and a desire to continuously explore. He is                       passionate about developing practical solutions that                       combine his work experience as a founder of Rolling Stones                       Rock Bar and Lemon Farm House, Pokhara.",
  },
  {
    name: "Ajay K.C, Technology Director",
    img: ajay,
    description:
      "                        Ajay is technically sound Mechanical Engineer with a                       degree from Chalmers University. He has worked inOperations, Project Management, Production Planning & Control in various hydropower companies of Nepal and left everything to work for ustainability with us. ",
  },
  {
    name: "Bimal Bastola, Executive Director",
    img: bimalbastola2,
    description:
      "        Bimal is passionate about doing things that can create                       impact. He chose to leave the dream job (working with a                       reputed Automobile brand JEEP) of many mechanical                       engineers to pursue his dream as an entrepreneur in his                       own city and started Ek Call Home Solutions in Pokhara. ",
  },
  {
    name: "Nirajan Ghimire, Head of R&D",
    img: nirajan2,
    description:
      "   Nirajan is a Mechanical engineer graduate from Kathmandu                        University. He worked on the R&D of various projects                       related to plastics commodities, wind & hydro turbines.                      His passion for working in research based green rojects                       pulled him to join the team of Green Road.  ",
  },
  {
    name: "Rajib Subedi, Chief Civil Engineer",
    img: rajivsubedi,
    description:
      "Rajib graduated from Pokhara Engineering Collage, Nepal                             with a degree in Civil Engineering. He has a broad                             experience in the road construction sector of Nepal and                            currently runs his own construction company at Pokhara. He                             is interested in fixing infrastructure problems with                             sustainable solutions.",
  },
  {
    name: "Pratima Baral, Software Developer",
    img: pratima,
    description:
      " Pratima is Computer Engineer graduate from Advanced                            College of Engineering and Management. She believes in                            hardwork and perseverance, can spend hours in research and                             development. She is self starter, motivated and always                             enthusiastic in learning new things.",
  },
];

const Team = () => {
  return (
    <>
      <Navbar />
      <Mission />
      <TeamContainer id="team">
        <TeamTitle>The Greenopreneurs Leadership</TeamTitle>
        <MDBRow className="justify-content-center">
          {teamMembers.map((member, index) => (
            <MDBCol
              key={index}
              md="4"
              className="mb-4 d-flex justify-content-center"
            >
              <TeamCard>
                <TeamIcon src={member.img} alt={member.name} />
                <TeamName>{member.name}</TeamName>
                <MDBMask className="flex-center" overlay="">
                  <TeamDescription>{member.description}</TeamDescription>
                </MDBMask>
              </TeamCard>
            </MDBCol>
          ))}
        </MDBRow>
      </TeamContainer>
      <Footer />
    </>
  );
};

export default Team;
