import React from "react";
import { Nav, Navbar as Navvbar } from "react-bootstrap";
import greenlogo from "../../../images/logo.png";
import { NavLinks } from "../../Navbar/NavbarElements";

const BlogNavbar = () => {
  return (
    <Navvbar bg="white" expand="lg" sticky="top">
      <Navvbar.Brand href="/">
        <img
          src={greenlogo}
          alt=" "
          width="100"
          height="100"
          className="d-inline-block align-top"
        />
      </Navvbar.Brand>
      <Navvbar.Toggle aria-controls="navbarScroll" />
      <Navvbar.Collapse id="navbarScroll">
        <Nav className="ml-auto" style={{ maxHeight: "250px" }} navbarScroll>
          <Nav.Link className="linkText">
            <NavLinks to="first">INSIDE GREEN ROAD</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText">
            <NavLinks to="second">GREEN TECH</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText">
            <NavLinks to="third">ClIMATE CHANGE</NavLinks>
          </Nav.Link>

          <Nav.Link className="linkText">
            <NavLinks to="fourth">ECO STORIES</NavLinks>
          </Nav.Link>
        </Nav>
      </Navvbar.Collapse>
    </Navvbar>
  );
};
export default BlogNavbar;
