import React from "react";
import { ExternalLink } from "react-external-link";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterWrap,
  InfoP,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <FooterContainer id="contact">
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Quick Links </FooterLinkTitle>

              <FooterLink to="how">How it works?</FooterLink>
              <FooterLink to="why">Why Green road?</FooterLink>
              <FooterLink to="products">Products</FooterLink>
              <FooterLink to="team">About</FooterLink>
              <FooterLink to="news">Media</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Social media </FooterLinkTitle>
              <ExternalLink
                href="https://www.facebook.com/greenroadnepal/"
                target="blank"
                style={{ color: "#ffffff" }}
              >
                Facebook
              </ExternalLink>
              <ExternalLink
                href="https://www.instagram.com/"
                target="blank"
                style={{ color: "#ffffff" }}
              >
                Instagram
              </ExternalLink>
              <ExternalLink
                href="https://www.youtube.com/channel/UCdNV5etj53vgQHXejfukiiQ"
                target="blank"
                style={{ color: "#ffffff" }}
              >
                Youtube
              </ExternalLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact us </FooterLinkTitle>
              <InfoP>
                Amarsingh - 10, Pokhara
                <br />
                Mobile: 9856035404, 9846732207
                <br />
                greenroadnp@gmail.com
              </InfoP>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo
              to="/"
              onClick={toggleHome}
              style={{ color: "#ffffff" }}
            >
              Green Road ( Non- Profit Organization )
            </SocialLogo>
            <WebsiteRights>
              GRWM &copy; {new Date().getFullYear()} All rights reserved.
            </WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.facebook.com/greenroadnepal/"
                target="blank"
                aria-label="Facebook"
              >
                <FaFacebook />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.instagram.com/"
                target="blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              <SocialIconLink
                href="https://www.youtube.com/channel/UCdNV5etj53vgQHXejfukiiQ"
                target="blank"
                aria-label="Youtube"
              >
                <FaYoutube />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
