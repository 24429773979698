import React, { Component } from "react";
import Slider from "react-slick";
import Background from "./Background";
import {
  B1Card,
  BCard,
  BH,
  BH1,
  BlogContainer,
  BParagraph,
  BWrapper,
  Heading,
} from "./BlogElements";
import BlogNavbar from "./BlogNavbar";
import CircularEconomy from "./CircularEconomy";
import Goals from "./Goals";
import GreenBricks from "./GreenBricks";
import GreenBricks1 from "./GreenBricks1";
import GreenCurriculum from "./GreenCurriculum";
import GreenRoad from "./GreenRoad";
import WasteAuditing from "./WasteAuditing";

export default class blog extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <>
        <BlogNavbar />
        <Slider {...settings}>
          <BlogContainer>
            <Heading>
              <br />
              Plactics roads: 2X durable
              <br />& environmental friendly
            </Heading>
            <br />

            <BParagraph>2 Min Read</BParagraph>
          </BlogContainer>

          <BlogContainer>
            <Heading>
              <br />
              You have an option for
              <br /> greener construction practices:
              <br /> Green Bricks
            </Heading>
            <br />

            <BParagraph>2 Min Read</BParagraph>
          </BlogContainer>

          <BlogContainer>
            <Heading>
              <br />
              Green road is helping to meet
              <br /> sustainable development goals
              <br />- 2030
              <br />
            </Heading>
            <br />

            <BParagraph>2 Min Read</BParagraph>
          </BlogContainer>

          <BlogContainer>
            <Heading>
              <br />
              The Green Roadway:
              <br />
              Circular Economy Model for
              <br />
              Sustainability of Earth
              <br />
            </Heading>
            <br />

            <BParagraph>2 Min Read</BParagraph>
          </BlogContainer>
        </Slider>
        <br />
        <br />
        <BWrapper id="first">
          <BCard>
            <Background />
          </BCard>
        </BWrapper>
        <br />
        <br />

        <BWrapper id="second">
          <B1Card>
            <BH>GREEN TECH</BH>

            <GreenRoad />
          </B1Card>
          <B1Card>
            <GreenBricks />
          </B1Card>
          <B1Card>
            <GreenBricks1 />
          </B1Card>
        </BWrapper>
        <br />
        <br />
        <BWrapper>
          <BCard id="third">
            <BH1>ClIMATE CHANGE</BH1>
            <GreenCurriculum />
          </BCard>
          <BCard>
            <WasteAuditing />
          </BCard>
        </BWrapper>
        <br />
        <br />

        <BWrapper>
          <B1Card id="fourth">
            <BH1>ECO STORIES</BH1>
            <Goals />
          </B1Card>
          <B1Card>
            <CircularEconomy />
          </B1Card>
        </BWrapper>
        <br />
        <br />
        <br />
        <br />
      </>
    );
  }
}
