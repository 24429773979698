import React, { Component } from 'react';
import { BH2 } from '../BlogElements';
// import pokhararoad from '../../../images/pokhararoad.png'
import { Button, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import ToggleDisplay from 'react-toggle-display';
import benifits from '../../../images/benifits.png';


export default class GreenRoad extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { show: false };
  }
 
  handleClick() {
    this.setState({
      show: !this.state.show
    });
  }

  render(){
    const { show } = this.state;
    let title = this.state.show? "Show less": "Read more";

        return(
        <>
        <BH2>Learn why plastics are the most useful resources for road construction?</BH2>
        

Plastics are the inventions that have paved a road for the rapid technological 
advancement and exponential development in the world but it is paving a huge 
potential threat to the future of mankind. While plastic is one of the greatest 
discoveries of the 20
th century, the management of these non-biodegradable 
materials is a huge problem whose solution is not being reflected upon. This is a 
multidimensional problem but the major change needs to happen in the mindset of 
humans who have the power to challenge the existing system. 
<ToggleDisplay if={this.state.show} tag="section">
<Col xs={18} md={14} className="m-auto">
                <Image src= {benifits} fluid />
                </Col>
The benefits of paving 
Green Roads can be simply put like this:
It addresses the non-biodegradability of plastics and helps in lengthening the life-span 
of plastic materials which would either end up being dumped in the landfills or the 
ocean posing a threat to the biodiversity of the planet. Some of the major day-to-day 
problems from plastics are choking of drains, promotion of stagnation of water which 
can be a breeding ground for many vectors carrying diseases, and release of toxic 
gases upon incineration.
<br />
<br />
Plastic‐tar roads have benefits over conventional roads such as the overall reduction 
in bitumen consumption by 8%, enhanced load carrying strength, reduced wear and 
tear, prevent the release of 3 tonnes of CO2 (compared to disposal by burning) into 
the atmosphere, increased road strength, excellent resistance to water and water 
stagnation, no stripping and potholes formation, enhanced binding, reduced rutting 
and raveling, improved soundness property, the negligible maintenance cost of the 
road, no leaching of plastics and no effect of UV radiation. Waste plastic that can be 
used includes cups, carry bags, polythene and polypropylene foams, and thermocol. 
Apart from improving the quality and lifetime of roads, green roads shall benefit waste 
management, road construction and maintenance, and public health to an 
astonishing degree.
<br />
<br />
The main problems in the construction are the determination of plastic hotspots which 
would allow clean plastic sourcing as raw materials for the road which would ease 
supply constraints. That is why Green Roads is working on the Waste Audit sector so 
that a good end-of-life value can be provided for the wastes if properly segregated. 
Lack of consistent demand and trust for the companies who pave plastic roads is also 
a challenge for now.Plastics are thus, a boon to humanity, and improper management is paving roads to 
a human-built catastrophe due to the same plastic. 
<br />
<br />
The utilization of plastics in the 
road doesn't just utilize plastic but improves important properties like water resistance 
of roads with additional financial benefits by saving costs on the whole road 
construction process. Thus, plastic road construction can be considered as a way to 
manage those wastes which would rather have gone to landfills to never decay or 
made their way to the oceans to affect marine biodiversity.

</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>
</>

        )}

  }
