import React, { Component } from 'react';
import { Button, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import ToggleDisplay from 'react-toggle-display';
import goal11 from '../../../images/goal11.png';
import goal13 from '../../../images/goal13.png';
import goal3 from '../../../images/goal3.png';
import goal7 from '../../../images/goal7.png';
import goal8 from '../../../images/goal8.png';
import goal9 from '../../../images/goal9.png';
import { BH2 } from '../BlogElements';

  export default class Goals extends Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.state = { show: false };
    }
   
    handleClick() {
      this.setState({
        show: !this.state.show
      });
    }

    render(){
      const { show } = this.state;
      let title = this.state.show? "Show less": "Read more";
        return(
        <>
        <BH2>How Green road is helping to meet sustainable development goals - 2030</BH2>
        
The Sustainable Development Goals (SDGs) are a set of 17 interconnected global 
goals intended to serve as a "roadmap to a brighter and more sustainable future for 
everybody”. The goal is to eradicate poverty, protect the environment, and ensure 
that all people, both now and in the future, live in peace and prosperity. The 17 SD 
goals are wide-ranging and intertwined.
<br />
<br />
Green road Waste Management is an innovative company entirely devoted to 
making the world a better place by managing trash. The main objective of Green 
road is utilizing and bringing the value out of waste. Major works done by Green 
road are the construction of roads and bricks from plastic waste and planning to 
work in waste-to-energy. Green road is helping to protect the environment for future 
generations. Green road helps to achieve some aims of sustainable development. 
Effort of Green road has a favorable impact on nearly all of the goals, but it has 
stronger impact on these goals:
<br />
<br />

<ToggleDisplay if={this.state.show} tag="section">


Goal 3: Good Health and Well Being
<br />
<Col xs={9} md={4} className="m-auto">
                <Image src= {goal3} fluid />
                </Col>
Green road is an environmental friendly company constantly working for the 
betterment of the environment. Plastic waste and trash, gases and leachate are 
global the burning issue. Our company has started to work focusing on the effects of 
these wastes and gases which are the main reason for the unhealthy health and 
environment. Proper management, treatment and utilization of plastic along with 
other municipal wastes has least effect in environment as well as in human health 
which ensure that all people of all ages have healthy lives and are well-adjusted.
 <br />
 <br />
Goal 7: Affordable and clean energy
<br />
<Col xs={9} md={4} className="m-auto">
                <Image src= {goal7} fluid />
                </Col>
Energy is at the base of essentially every important concern and opportunity 
confronting the globe currently. Sustainable energy is a chance to change people's 
lives, economies, and the world. Green road is working in transforming waste to 
energy which will benefit environment in both ways; the waste of the environment is 
also utilized and the affordable, reliable and modern energy is also produced which do not contaminate the environment, and the fuel produced conserve energy 
through energy efficiency methods. 

<br />
 <br />
Goal 8: Decent work and economic growth
<br />
<Col xs={9} md={4} className="m-auto">
                <Image src= {goal8} fluid />
                </Col>
Decent work means that everyone has the opportunity to find work that is both 
productive and pays a fair wage because poverty can only be eradicated if people 
have steady and well-paid jobs. In Green road there are greater opportunities for 
personal growth and community involvement and also pays fair wage. Our company 
encourages long-term, inclusive, and sustainable economic growth, as well as full and 
productive employment and decent work for everyone. We ensure that economic 
progress produces respectable and meaningful jobs while avoiding environmental 
harm.
 
<br />

<br />
Goal 9: Industries, innovation and infrastructure
<br />
<Col xs={9} md={4} className="m-auto">
                <Image src= {goal9} fluid />
                </Col>
Building resilient infrastructure, promoting sustainable industrialization, and fostering 
innovation are all goals of SDG 9. Our industry and infrastructure must be modernized 
to face future challenges. Green road is itself an inventive company which 
encourages the development of innovative sustainable technologies and ensuring 
that all people have equal and unrestricted access to information. Green road 
focuses on establishing dependable and long-term infrastructure solutions that 
promote economic development and human well-being while remainining financially 
feasible.

<br />

<br />
 
Goal 11: Sustainable cities and communities 
<br />
<Col xs={9} md={4} className="m-auto">
                <Image src= {goal11} fluid />
                </Col>
SDG 11 includes goals such as reducing cities' environmental impacts and providing 
safe and inclusive green and public spaces. Cities account for 60–80 percent of 
energy consumption and 75 percent of carbon emissions despite occupying only 3% 
of the Earth's territory. Green Road has been reducing environmental impacts since 
past 2 years and has been providing safe public spaces by managing the wastes 
dumped in public places.

<br />

<br /> 
 
Goal 13: Climate action
<br />
<Col xs={9} md={4} className="m-auto">
                <Image src= {goal13} fluid />
                </Col>
In order to avert the worst effects of climate change, science mandates that 
greenhouse gas emissions be capped as soon as possible and then reduced to zero 
by 2050. As more people turn to renewable energy and a variety of other steps to cut 
emissions and improve adaptation efforts, the speed of change is rising. At Green 
road, we are practicing to invest in long-term solutions: fossil fuel subsidies must be 
phased out, and polluters must be held accountable for their emissions. We are 
engaged in green jobs and growth that is both sustainable and inclusive.


</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>
<br />
<br />
</>

        )}

  }
