import React, { Component } from 'react';
import { Button, Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import greenbrickscomp from '../../../images/greenbrickscomp.jpg';
import { BH2 } from '../BlogElements';

import ToggleDisplay from 'react-toggle-display';


  export default class GreenBricks1 extends Component {
    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
      this.state = { show: false };
    }
   
    handleClick() {
      this.setState({
        show: !this.state.show
      });
    }

    render(){
      const { show } = this.state;
      let title = this.state.show? "Show less": "Read more";

        return(
        <>
        <BH2>You have an option for greener construction practices: Green Bricks</BH2>
        

        Green Interlocking Bricks, also called Earth Bricks, is a strong, durable, and 
earthquake-resistant building material suitable for the construction of houses, 
buildings, schools, hospitals, and eventually cities in Nepal. It is a proven earthquakeresistant technology that has been used for decades in earthquake-affected areas 
across India, Pakistan, Iran, Europe, and many other countries. It enables to build high 
quality and earthquake-resistant structures at a low cost. Also, on why it is called 
"Green Brick", there is no kiln involved which would mix up toxic gases and fumes into 
the atmosphere but they are made into blocks by applying high pressure to the soil. 
<br />
<br />
The Green Interlocking Bricks consists of Stone dust, Sand, Cement, and Soil stabilizer. 
The mixture is compressed in a machine which results in a compacted brick with high 
density. The bricks are then stacked and cured for 21 days – the cement sets and 
bonds with the sand stabilizing the brick during the curing period.
Greens Interlocking bricks characteristics: 
<br />
<ToggleDisplay if={this.state.show} tag="section">
<Col xs={18} md={14} className="m-auto">
                <Image src= {greenbrickscomp} fluid />
                </Col>

• Its compressive strength is around 7-10 Mpa; more than the strength of red bricks & 
AAC 
<br />
• The weight of these Green bricks come to be around 8 Kg per individual Brick.
<br />
• The dimensions of these bricks are 30Cm x 15 cm x 10cm
<br />
• These bricks, being environmentally friendly and cost-efficient, are also volume 
efficient. Ie. The volume of 1 Green brick = volume of 3 red burnt bricks
<br />
Benefits of green bricks:
<br />
1. Eco friendly! No burning while making of these bricks.
<br />
2. Walls will be much strong and earthquake resistant due to interlocking 
properties.
<br />
3. Strong and durable (6-10 Mpa)
<br />
4. Cost-effective/cheap
<br />
5. Faster construction is possible
<br />
6. Earthquake resistant
<br />
7. Plaster not required in finishing 
<br />
8. Better sound and heat insulation
<br />

</ToggleDisplay>
<Button onClick={ () => this.handleClick()} variant ="success" size = "sm">{title}</Button>
</>

        )}

  }
